<template>
  <component
    :is="componentToRender"
    class="AppButton text-base py-3 px-5 border border-primary-1-100 text-center uppercase"
    :class="{
      'is-default': variant === 'default',
      'is-white': variant === 'white',
      'is-outline': variant === 'outline',
      'disable-hover': disableHover,
      'opacity-50 pointer-events-none': disabled,
      'cursor-wait pointer-events-none': loading,
      'tracking-widest': $i18n.locale === 'en',
      'rounded-xl': rounded,
      'self-start': position === 'start',
      'self-center': position === 'center',
      'self-end': position === 'end',
    }"
    :disabled="disabled"
    v-bind="$attrs"
    :aria-label="ariaLabel"
  >
    <Spinner
      v-if="loading"
      class="w-6 h-6 mx-auto"
      :class="{
        'text-white': inverted,
      }"
    />
    <slot v-else />
  </component>
</template>

<script setup lang="ts">
const props = defineProps({
  as: {
    type: String,
    default: 'button',
  },
  white: {
    type: Boolean,
    default: false,
  },
  inverted: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: 'default',
    validator: (value: string) => ['default', 'outline', 'danger', 'gray', 'white', 'secondary'].includes(value),
  },
  ariaLabel: {
    type: String,
    default: null,
  },
  disableHover: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: true,
  },
  position: {
    type: String,
    default: 'start',
  },
});

const isAutoImportedComponent = (name: string) => {
  return ['AppLink'].includes(name);
};

const componentToRender = computed(() => {
  if (isAutoImportedComponent(props.as)) {
    return props.as;
  }

  return props.as;
});
</script>

<style lang="postcss" scoped>
.AppButton {
  &.is-default {
    @apply bg-primary-1-100 text-white;

    &:not(.disable-hover):hover {
      @apply opacity-80;
    }
  }

  &.is-white {
    @apply text-primary-1-100 bg-white border-none;

    &:not(.disable-hover):hover {
      @apply bg-primary-1-100 text-white;
    }
  }

  &.is-outline {
    @apply border outline-primary-1-20 border-primary-1-100 text-primary-1-100 bg-transparent;

    &:not(.disable-hover):hover {
      @apply bg-primary-1-100 text-white;
    }
  }

  &:disabled {
    @apply opacity-50;
    pointer-events: none;
  }
  &:focus {
    @apply outline-primary-1-20 border-primary-1-20;
  }
}
</style>
